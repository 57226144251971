import { BaseConfig } from './config'

const isIPAddressOrDuck =
    window.location.hostname.split('.').length === 4 ||
    window.location.hostname.includes('txcontrol.ubsm.ma') ||
    window.location.hostname.includes('localhost')

const apiPort = 61301
const grafanaPort = 61300

const getAPIPort = () => {
    if (window.location.hostname.includes('172.21')) {
        return 3010
    }

    const customPort = window.location.port
    if (customPort) {
        if (customPort === '62080') {
            return 62301
        }
        if (customPort === '61080') {
            return 61301
        }
    }

    return apiPort
}

const getGrafanaPort = () => {
    const customPort = window.location.port
    if (customPort) {
        if (customPort === '62080') {
            return 62300
        }
        if (customPort === '61080') {
            return 61300
        }
    }

    if (window.location.hostname.includes('172.21')) {
        return 3000
    }
    return grafanaPort
}

export const productionConfig: BaseConfig = {
    api: {
        baseURL: isIPAddressOrDuck
            ? `http://${window.location.hostname}:${getAPIPort()}`
            : `https://${window.location.hostname}/api`,
    },
    grafana: {
        baseURL: isIPAddressOrDuck
            ? `http://${window.location.hostname}:${getGrafanaPort()}/grafana_frame`
            : `https://${window.location.hostname}/grafana_frame`,
    },
    fileTemplates: {
        devices: `http://${window.location.hostname}:${getAPIPort()}/public/templates/devices_import.csv`,
    },
}
