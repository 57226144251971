import { BaseConfig } from './config'

// const isIPAddress = window.location.hostname.split('.').length === 4

const ApiPort = 61301
// const GRAFANA_PORT = 3000

export const developmentConfig: BaseConfig = {
    api: {
        baseURL: 'https://seritel.txcontrol.it/api',
    },
    grafana: {
        baseURL: 'https://development.txcontrol.it/grafana_frame',
    },
    fileTemplates: {
        devices: `http://${window.location.hostname}:${ApiPort}/public/templates/devices_import.csv`,
    },
}
