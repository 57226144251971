import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faToolbox } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../utils/routes'
import { NetworkGroup } from '../../types/data/system'
import { Device } from '../../types/data/alarm'
import * as tableLocales from '../../resources/tableLocales/tableLocales'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserDefaultTablePageSize } from '../../store/actions/user'
import { AvailableTablePageSizes } from '../../utils/enum/UserActivityTypes'
import { statusLabelForDevice, statusPinStyleForDevice } from '../../utils/functions'
import { COLORS } from '../../styles/constants'

interface Props {
    currentGroup: NetworkGroup
    filterParams: any
    reloads: boolean
}

const GroupDevicesTable: React.FC<Props> = (props) => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [pageSize, setPageSize] = useState(user.defaultTablePageSize)
    const tableRef = React.useRef<any>()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (tableRef.current && props.filterParams) {
            resetData()
        }
    }, [props.filterParams])

    const resetData = useCallback((headerToSortBy = '', order = '') => {
        tableRef.current.onQueryChange({ page: 0, orderBy: headerToSortBy, orderDirection: order })
    }, [])

    useEffect(() => {
        if (tableRef.current && props.reloads !== undefined) {
            resetData()
        }
    }, [props.reloads])

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('fields.status'),
                field: 'status',
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.maintenanceMode ? (
                                <div className="dot-container">
                                    <div className="dot-tooltip">{t('fields.onMaintenanceMode')}</div>
                                    <FontAwesomeIcon
                                        icon={faToolbox}
                                        color={COLORS.palette.darkBlue}
                                        style={{ width: '20px', height: '20px', marginLeft: '7px' }}
                                    />
                                </div>
                            ) : (
                                <div className="dot-container">
                                    <div className="dot-tooltip">{t(statusLabelForDevice(rowData))}</div>
                                    <span className={statusPinStyleForDevice(rowData)} />
                                </div>
                            )}
                        </>
                    )
                },
            },
            {
                title: t('fields.name'),
                field: 'name',
                render: (rowData: Device) => (
                    <a
                        className="colorRed table-link-value"
                        // onClick={() => navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`, { state: { isView: true } })}
                        href={`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`}
                    >
                        {rowData.name}
                    </a>
                ),
            },
            {
                title: t('fields.brand'),
                field: 'brand',
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.deviceModel !== undefined && (
                                <>
                                    {user.idSysGrant === 0 ? (
                                        <span
                                            className="colorRed table-link-value"
                                            onClick={() =>
                                                navigate(`/${ROUTE_NAMES.BRANDS}/${rowData.deviceModel.brand.ID}`)
                                            }
                                        >
                                            {rowData.deviceModel.brand.name}
                                        </span>
                                    ) : (
                                        <span>{rowData.deviceModel.brand.name}</span>
                                    )}
                                </>
                            )}
                        </>
                    )
                },
            },
            {
                title: t('fields.model'),
                field: 'model',
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.deviceModel !== undefined && (
                                <>
                                    {user.idSysGrant === 0 ? (
                                        <span
                                            className="colorRed table-link-value"
                                            onClick={() => navigate(`/${ROUTE_NAMES.MODELS}/${rowData.deviceModel.ID}`)}
                                        >
                                            {rowData.deviceModel.model}
                                        </span>
                                    ) : (
                                        <span>{rowData.deviceModel.model}</span>
                                    )}
                                </>
                            )}
                        </>
                    )
                },
            },
            {
                title: t('fields.communicationProtocol'),
                field: 'id_communication_protocol',
                render: (rowData: any) => {
                    return (
                        <>
                            {rowData.idCommunicationProtocol === 2 ? (
                                <span>
                                    {t('fields.snmp')} - {rowData.ipAddress}:{rowData.snmpPort}
                                </span>
                            ) : rowData.idCommunicationProtocol === 3 ? (
                                <span>
                                    {t('fields.sms')} - {rowData.phoneNumber}
                                </span>
                            ) : null}
                        </>
                    )
                },
            },
        ]
        return columns
    }, [navigate, t, user.idSysGrant])

    const actions = useMemo(() => {
        const actions = [
            (rowData: Device) =>
                (user.idSysGrant === 0 ||
                    user.managedNetworks.find((networkId) => rowData.idNetwork === networkId)) && {
                    icon: () => <FontAwesomeIcon icon={faPen} color={COLORS.palette.darkGrey} size="xs" />,
                    onClick: () => {
                        navigate(`/${ROUTE_NAMES.DEVICE}/${rowData.ID}`)
                    },
                    tooltip: t('common.edit'),
                },
        ]
        return actions
    }, [navigate, t, user.idSysGrant, user.managedNetworks])

    return (
        <>
            <MaterialTable
                columns={columns}
                tableRef={tableRef}
                data={async (query) =>
                    new Promise((resolve) => {
                        // const newOffset = pageSize + pageSize * (query.page - 1)
                        const newOffset = pageSize * query.page
                        let url = 'devices'
                        url += `?limit=${pageSize}`
                        url += `&offset=${newOffset}`
                        url += `&group=${props.currentGroup.id}`

                        if (
                            query.orderBy !== undefined &&
                            query.orderBy.field !== undefined &&
                            (query.orderDirection as string) !== ''
                        ) {
                            url += `&sort_by=${query.orderBy.field.toString()}&order_by=${
                                query.orderDirection[0].toUpperCase() + query.orderDirection.slice(1)
                            }`
                        }
                        if (props.filterParams && props.filterParams !== null) {
                            Object.keys(props.filterParams).map((key: string) => {
                                url += `&${key}=${props.filterParams[key]}`
                                return
                            })
                        }
                        void CustomAxios.get(url).then((response) => {
                            console.log({ response })

                            resolve({
                                data: response.data.data,
                                page: query.page,
                                totalCount: response.data.count,
                            })
                        })
                    })
                }
                actions={actions}
                title=""
                options={{
                    pageSize,
                    pageSizeOptions: AvailableTablePageSizes,
                    search: false,
                    // actionsColumnIndex: 99,
                    actionsColumnIndex: -1,
                }}
                onChangeRowsPerPage={(pageSize) => {
                    dispatch(setUserDefaultTablePageSize(pageSize))
                    setPageSize(pageSize)
                    resetData()
                }}
                localization={tableLocales[user.language.split('-')[0]]}
            />
        </>
    )
}

export default GroupDevicesTable
